import React, { useEffect, useRef, useState } from 'react';
import "../../assets/css/coupons.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import booking from "../../assets/css/bookingen.module.css";
import { convertDateToTimeZone } from '../Dashboard/utils/timeUtils';
import moment from 'moment';
import ApiCall from '../Constants/ApiCall';
import AlertDismissible from '../Common_Fun/AlertDismissible';
import { Dropdown } from 'react-bootstrap';

const CouponForm = (props) => {
    console.log(props, 'props')
    const sessionDate = sessionStorage.getItem("date");
    const newDate = convertDateToTimeZone(sessionDate, props.timeZonename, 'MM-DD-YYYY')
    const endDate = moment(newDate).add(1, 'days').format('MM-DD-YYYY')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [handle, setHandle] = useState(false)
    const [formData, setFormData] = useState({
        category: props.editC ? props.editC.category : "RoomType",
        roomType: props.editC
            ? props.editC.roomType.map((room) => room.id) // Map `roomType` to an array of IDs
            : [],
        max_days: props.editC ? props.editC?.max_days : '',
        min_days: props.editC ? props.editC?.min_days : '',
        couponType: props.editC ? props.editC.discount > 0 ? 'discount' : 'fixedAmount' : "discount",
        dateRange: { start: props.editC ? moment(props.editC?.valid_from).format('MM-DD-YYYY') : newDate, end: props.editC ? moment(props.editC?.valid_to).format('MM-DD-YYYY') : endDate },
        image: props.editC ? props.editC?.img : null,
        title: props.editC ? props.editC.title : '',
        discount: props.editC ? props.editC?.discount : '',
        fixedAmount: props.editC ? props.editC?.fixedAmount : '',
        max_amount:props.editC ? props.editC?.max_amount :'',
        min_amount:props.editC ? props.editC?.min_amount :''

    });
    const [errors, setErrors] = useState({});
    const [selectedBannerImage, setSelectedBannerImage] = useState([]);
    const [bannerPreviews, setBannerPreviews] = useState([]);
    useEffect(() => {
        if (props.editC && props.editC?.img != "") {
            setBannerPreviews([{
                previewUrl: props.editC?.img
            }])
        }
    }, [])
    console.log(bannerPreviews, 'dkdkdk')

    const fileInputRef = useRef(null);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.category) newErrors.category = "Category is required.";
        // if (formData.category === "roomtype" && !formData.roomType) {
        //     newErrors.roomType = "Room type is required.";
        // }

        if (formData.category !== "Items") {
            if (!formData.max_days || isNaN(formData.max_days) || formData.max_days <= 0) {
                newErrors.max_days = "Enter a valid Max Days.";
            }
            if (!formData.min_days || isNaN(formData.min_days) || formData.min_days <= 0) {
                newErrors.min_days = "Enter a valid Min Days.";
            }
        }

        if (!formData.couponType) newErrors.couponType = "Coupon type is required.";
        if (!formData.dateRange.start || !formData.dateRange.end) {
            newErrors.dateRange = "Date range is required.";
        }

        if (!formData.title) newErrors.title = "Title is required.";
        if (formData.roomType?.length <= 0 && formData?.category === 'RoomType') {
            newErrors.roomType = "Rooms is required.";
        }

        // CouponType-specific validation
        if (formData.couponType === "discount" && (!formData.discount || formData.discount <= 0)) {
            newErrors.discount = "Enter a valid percentage discount.";
        }
        if (formData.couponType === "fixedAmount" && (!formData.fixedAmount || formData.fixedAmount <= 0)) {
            newErrors.fixedAmount = "Enter a valid fixed amount.";
        }
        if (formData.category === "Items") {
            if (!formData.min_amount || formData.min_amount <= 0) {
                newErrors.min_amount = "Enter a valid minimum amount.";
            } 
            if (!formData.max_amount || formData.max_amount <= 0) {
                newErrors.max_amount = "Enter a valid maximum amount.";
            }
            if (formData.min_amount > 0 && formData.max_amount > 0) {
                if (formData.min_amount >= formData.max_amount) {
                    newErrors.min_amount = "Minimum amount should be less than maximum amount.";
                    newErrors.max_amount = "Maximum amount should be greater than minimum amount.";
                }
            }
        }

        return newErrors;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => {
            // Check if the category is not "RoomType" and clear roomType if true
            if (name === "category" && value !== "RoomType") {
                return {
                    ...prev,
                    [name]: value,
                    roomType: [], // Clear roomType if category is not 'RoomType'
                };
            }

            // Update discount/fixedAmount dynamically based on couponType
            if (name === "couponType") {
                return {
                    ...prev,
                    couponType: value,
                    discount: value === "discount" ? "" : undefined, // Clear discount if couponType is 'discount'
                    fixedAmount: value === "fixedAmount" ? "" : undefined, // Clear fixedAmount if couponType is 'fixedAmount'
                };
            }

            // Default behavior for other form fields
            return {
                ...prev,
                [name]: value,
            };
        });

        setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error on change
    };
    console.log(errors, formData, 'errors')
    const handleEvent = (event, picker) => {
        setFormData((prev) => ({
            ...prev,
            dateRange: {
                start: picker.startDate.format("MM-DD-YYYY"),
                end: picker.endDate.format("MM-DD-YYYY"),
            },
        }));
        setErrors((prev) => ({ ...prev, dateRange: "" }));
    };



    const handleBannerImageChange = (e) => {
        setSelectedBannerImage(e.target.files);
        const files = Array.from(e.target.files);
        const previews = files.map((file) => ({
            file,
            previewUrl: URL.createObjectURL(file),
        }));
        setBannerPreviews((prevPriviews) => [...previews]);
        setHandle(false)
    };

    const removeImage = () => {
        setFormData((prev) => ({ ...prev, image: null }));
        setBannerPreviews([])
        setSelectedBannerImage([])
        setHandle(true)
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            const formDataToSend = new FormData();
            formDataToSend.append('method', 'save');
            formDataToSend.append('valid_to', moment(formData?.dateRange?.end).format('YYYY-MM-DD'));
            formDataToSend.append('valid_from', moment(formData?.dateRange?.start).format('YYYY-MM-DD'));
            formDataToSend.append('title', formData?.title);
            formDataToSend.append('category', formData.category);

            // Append image if it's available
            if (selectedBannerImage?.length > 0) {
                formDataToSend.append('img', selectedBannerImage[0]);
            }
            else {
                if (handle) {
                    formDataToSend.append('rm_img', true)
                }

            }

            // Add discount or fixedAmount based on formData
            if (formData.discount) {
                formDataToSend.append('discount', formData.discount);
            } else {
                formDataToSend.append('fixedAmount', formData.fixedAmount ? formData.fixedAmount : 0);
            }

            // Add roomType based on category
            if (formData.category !== 'RoomType') {
                // formDataToSend.append('roomType', []);  // If roomType is empty
            } else {
                formDataToSend.append('roomType', JSON.stringify(formData.roomType)); // Append array of room types
            }

            // Add max_days and min_days for certain categories
            if (formData.category !== 'Items') {
                formDataToSend.append('max_days', formData?.max_days);
                formDataToSend.append('min_days', formData?.min_days);
            } else {
                formDataToSend.append('max_days', 0);
                formDataToSend.append('min_days', 0);
                formDataToSend.append('max_amount', formData?.max_amount);
                formDataToSend.append('min_amount', formData?.min_amount);
            }

            // Now, update formData with the body values
            setFormData((prevState) => ({
                ...prevState,
                ...Object.fromEntries(formDataToSend.entries()),  // Convert FormData to object for state
            }));

            // Send the FormData object via POST request


            handlePostCoupon(formDataToSend);



            console.log("Form Submitted with FormData:", formData, formDataToSend); // You can log the formData now
        }
    };

    const handlePostCoupon = async (data) => {
        const property = localStorage.getItem("property");
        let api
        if (props.editC) {
            api = `${props.url}generate_coupon_code/${property}/${props.editC.id}/`
        }
        else {
            api = `${props.url}generate_coupon_code/${property}/`
        }


        const token = JSON.parse(localStorage.getItem("logintoken"));
        const apiResponse = await fetch(api, {
            method: props.editC ? 'PUT' : "POST",
            headers: {
                Authorization: "Bearer " + token["token"],
            },
            body: data,
        });
        if (apiResponse) {
            setLoading(false)
            setTimeout(() => {
                setSuccess(true)
            }, 500)
            setTimeout(() => {
                props.setActiveTab('')
            }, 5000)
        }
    }
    const handleCancel = () => {
        console.log("Form Canceled");
        props.setActiveTab('');
    };
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    }
    const handleCheckboxChange = (e, categoryId) => {
        const { checked } = e.target;

        setFormData((prevState) => {
            // Ensure roomType is an array
            const currentRoomTypes = Array.isArray(prevState.roomType)
                ? prevState.roomType
                : [];

            let updatedRoomTypes;
            if (checked) {
                // Add the room type ID if it's not already in the array
                updatedRoomTypes = [...currentRoomTypes, categoryId];
            } else {
                console.log(currentRoomTypes, 'categoryId')
                // Remove the room type ID if it's unchecked
                updatedRoomTypes = currentRoomTypes.filter((existingId) => existingId !== categoryId);
            }

            // Update errors based on selection
            if (updatedRoomTypes.length > 0 && formData?.category === "RoomType") {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    roomType: [], // Clear error message if room types are selected
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    roomType: "Please select rooms", // Show error if no rooms are selected
                }));
            }

            return {
                ...prevState,
                roomType: updatedRoomTypes, // Update roomType with updated list
            };
        });
    };
    const dropdownRef = useRef(null);

    return (
        <div className="couponforms">
            <h4>Create Coupon</h4>
            <div style={{ borderTop: '2px dotted #838997', marginTop: '15px' }}></div>
            {success && (
                <AlertDismissible
                    type="success"
                    msg={`Coupon ${props.editC ? 'edited' : 'saved'} successfully`}
                    msgFun={() => setSuccess(false)}
                />
            )}
            <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
                {/* Coupon Category */}
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="category" className='couponlabel'>Add Title</label>
                    <input
                        id="title"
                        name="title"
                        placeholder='Add Title'
                        className='couponselect'
                        type='text'
                        defaultValue={formData.title}
                        onChange={handleChange}
                    />
                    {errors.title && <p className="error-text">{errors.title}</p>}
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="category" className='couponlabel'>Select Category</label>
                    <select
                        id="category"
                        name="category"
                        className='couponselect'
                        defaultValue={formData.category}
                        onChange={handleChange}
                    >
                        <option value="RoomType">RoomType</option>
                        <option value="Amenities">Amenities</option>
                        <option value="Items">Items</option>
                    </select>
                    {errors.category && <p className="error-text">{errors.category}</p>}
                </div>

                {/* Select Rooms */}

                {formData.category === "RoomType" && (
                    <div style={{ marginBottom: '15px' }}>
                        <Dropdown ref={dropdownRef} drop="down"   >
                            <Dropdown.Toggle
                                variant='success'
                                id="dropdown-roomType"
                                className={`${formData?.roomType?.length > 0 ? 'couponselect1': 'couponselect'}`}
                            >
                                Select Rooms
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "100%",height:140 , overflow:"scroll"}}
                                drop="down">
                                <div style={{ width: "100%", paddingLeft: 10 }} className='checkbox-container'>
                                    {props?.cRoomType?.length > 0 && props?.cRoomType.map((category) => (
                                        <div key={category.id} style={{ marginBottom: '10px' }}>
                                            <input
                                                type="checkbox"
                                                id={`${category.id}`} // Unique id for each checkbox
                                                defaultValue={category.room_all_type}
                                                // Check if this room type is selected
                                                defaultChecked={
                                                    props.editC?.roomType?.some(
                                                        (type) => type.id === category.id // Match based on `id`
                                                    ) || false
                                                }
                                                onChange={(e) => handleCheckboxChange(e, category.id)} // Handle checkbox change
                                            />
                                            <label htmlFor={`roomType-${category.id}`} style={{ marginLeft: '8px' }}>
                                                {category.room_all_type}
                                            </label>
                                        </div>
                                    ))}

                                </div>


                            </Dropdown.Menu>
                        </Dropdown>
                        {errors.roomType && <p className="error-text">{errors.roomType}</p>}
                    </div>
                )}

                {/* Max Days and Min Days */}
                {formData.category !== "Items" && (
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="min_days" className='couponlabel'>Min Days</label>
                            <input
                                id="min_days"
                                name="min_days"
                                placeholder='Min Days'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.min_days : formData.min_days}
                                onChange={handleChange}
                            />
                            {errors.min_days && <p className="error-text">{errors.min_days}</p>}
                        </div>
                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="max_days" className='couponlabel'>Max Days</label>
                            <input
                                id="max_days"
                                name="max_days"
                                placeholder='Max Days'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.max_days : formData.max_days}
                                onChange={handleChange}
                            />
                            {errors.max_days && <p className="error-text">{errors.max_days}</p>}
                        </div>
                    </div>
                )}


{formData.category === "Items" && (
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="min_days" className='couponlabel'>Min Amount</label>
                            <input
                                id="min_amount"
                                name="min_amount"
                                placeholder='Min Amount'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.min_amount : formData.min_amount}
                                onChange={handleChange}
                            />
                            {errors.min_amount && <p className="error-text">{errors.min_amount}</p>}
                        </div>
                        <div style={{ marginBottom: '15px', width: "100%" }}>
                            <label htmlFor="max_amount" className='couponlabel'>Max Amount</label>
                            <input
                                id="max_amount"
                                name="max_amount"
                                placeholder='Max Amount'
                                className='couponselect'
                                type='text'
                                defaultValue={props.editC ? props.editC?.max_amount : formData.max_amount}
                                onChange={handleChange}
                            />
                            {errors.max_amount && <p className="error-text">{errors.max_amount}</p>}
                        </div>
                    </div>
                )}
                

                {/* Coupon Type */}
                <div style={{ display: "flex", justifyContent: "space-between", gap: 17 }}>

                    <div style={{ marginBottom: '15px', width: "100%" }}>
                        <label htmlFor="couponType" className='couponlabel'>Coupon Type</label>
                        <select
                            disabled={props.editC}
                            id="couponType"
                            style={{ background: props.editC ? '#D3D3D3' : 'white' }}
                            name="couponType"
                            className='couponselect'
                            defaultValue={formData.couponType}
                            onChange={handleChange}
                        >
                            <option value="discount">Discount</option>
                            <option value="fixedAmount">Fixed Amount</option>
                        </select>
                        {errors.couponType && <p className="error-text">{errors.couponType}</p>}
                    </div>
                    <div style={{ marginBottom: '15px', width: "100%" }}>

                        {["discount", "fixedAmount"].includes(formData.couponType) && (
                            <>
                                <label
                                    htmlFor="discount"
                                    className='couponlabel'
                                >
                                    {formData.couponType === 'fixedAmount' ? 'Fixed Amount' : 'Discount'}
                                </label>
                                <input
                                    name={formData.couponType}
                                    className='couponselect' // Dynamically sets the name to "discount" or "fixedAmount"
                                    type="number"
                                    placeholder={
                                        formData.couponType === "discount"
                                            ? "Enter percentage discount"
                                            : "Enter fixed amount"
                                    }
                                    defaultValue={props.editC ? props.editC?.discount > 0 ? props.editC?.discount : props.editC?.fixedAmount : formData[formData.couponType] || ""}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        {formData.couponType === "discount" && errors.discount && (
                            <p className="error-text">{errors.discount}</p>
                        )}

                        {formData.couponType === "fixedAmount" && errors.fixedAmount && (
                            <p className="error-text">{errors.fixedAmount}</p>
                        )}
                    </div>
                </div>

                {/* Coupon Date Range */}
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="dateRanges" className='couponlabel'>Coupon Dates</label>
                    <DateRangePicker
                        className={booking.dateRange}
                        onApply={handleEvent}
                        initialSettings={{
                            autoApply: false,
                            showDropdowns: true,
                            locale: { format: "MM-DD-YYYY" },

                        }}
                        showTodayButton={true}
                    >
                        <input
                            type="text"
                            style={{ cursor: "pointer" }}
                            className="couponselect"
                            placeholder="Select Date Range"
                            defaultValue={
                                formData.dateRange.start && formData.dateRange.end
                                    ? `${formData.dateRange.start} - ${formData.dateRange.end}`
                                    : ""
                            }
                            readOnly
                        />
                    </DateRangePicker>
                    {errors.dateRange && <p className="error-text">{errors.dateRange}</p>}
                </div>

                {/* Image Upload */}
                <div style={{ marginBottom: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderRadius: '17px', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <button
                                type="button"
                                style={{
                                    backgroundColor: '#FF943D',
                                    border: '1px solid #ccc',
                                    borderRadius: '12px',
                                    padding: '6px 16px',
                                    cursor: 'pointer',
                                    color: "white",
                                    fontSize: '14px',
                                }}
                                onClick={handleFileInputClick}
                            >
                                Choose File
                            </button>
                            <span style={{ fontSize: '14px', color: 'gray' }}>
                                Choose Featured Image
                            </span>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleBannerImageChange}
                        />
                        {bannerPreviews?.length > 0 && (
                            bannerPreviews?.map((prev) => (
                                <>
                                    <div style={{ position: 'relative', marginTop: '15px' }}>
                                        <img
                                            src={prev.previewUrl}
                                            alt="Preview"
                                            style={{ width: '100%', height: '270px', objectFit: 'cover', borderRadius: '8px' }}
                                        />
                                        <div
                                            onClick={removeImage}
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: '30px',
                                                height: '30px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            ×
                                        </div>
                                    </div>
                                </>
                            ))
                        )}

                    </div>
                </div>

                {/* Submit and Cancel Buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 350 }}>
                    <button
                        disabled={loading}
                        type="submit"
                        className="couponselects"
                        style={{
                            backgroundColor: "#47ADFF",
                            color: "#fff",
                            border: "1px solid #47ADFF",
                            cursor: "pointer",
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                    >
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                    <button
                        type="button"
                        className="couponselects"
                        onClick={handleCancel}
                        style={{
                            backgroundColor: "white",
                            color: "#47ADFF",
                            border: "1px solid #47ADFF",
                            cursor: "pointer"
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CouponForm;