import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { FilterPanel } from "./FilterPanel";
import CouponGrid from "./CouponGrid";
import CouponForm from "./CouponForm";
import ApiCall from "../Constants/ApiCall";
import HistoryCoupons from "./HistoryCoupons";

const CouponManagement = (props) => {
  const [activeTab, setActiveTab] = useState(""); // 'history' or 'createNew'
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState([]);
  const [cRoomType,setCRoomType] = useState([])
  const [editC,setEditC] = useState(false)
  const [filters, setFilters] = useState({
    title: "",
    category: "",
    startDate: "",
    endDate: "",
  });

  const getCoupons = async () => {
    const property = localStorage.getItem("property");
    const api_call = await ApiCall(
      `${props.url}generate_coupon_code/${property}/`,
      "get"
    );
    if (api_call) {
      setCoupon(api_call?.coupon);
      setCRoomType(api_call.room_type)
     
      setLoading(false);
      console.log(api_call, "fkfkfkfk");
    }
  };
  useEffect(() => {
    if(activeTab ===''){
      setLoading(true);
      getCoupons();
    }
    
    
  }, [activeTab]);


  const couponpermissions = localStorage.getItem('user_permission')
 
 

  return activeTab === "create new" ? (
    <div style={{ padding: 20 }}>
      <CouponForm  couponpermission={JSON.parse(couponpermissions)} setEditC={setEditC} editC={editC} url={props.url}  timeZonename={props.timeZonename} cRoomType={cRoomType} activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  ) : activeTab === "history" ? (
    <div>
      <HistoryCoupons  couponpermission={JSON.parse(couponpermissions)}  activeTab={activeTab}
        setActiveTab={setActiveTab} setEditC={setEditC} editC={editC}  loading={loading} coupon={coupon} url={props.url}/>
    </div>
  ) : (
    <div
      className="main-content"
      style={{ display: "flex", flexDirection: "col" }}
    >
      <FilterPanel  filters={filters} setFilters={setFilters} />
      <CouponGrid
        couponpermission={JSON.parse(couponpermissions)}
        setEditC={setEditC}
        cRoomType={cRoomType}
        loading={loading}
        coupon={coupon}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        filters={filters}
      />
    </div>
  );
};

export default adminLayout(CouponManagement);
