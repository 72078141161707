const VideoLink = {
    "Dashboard":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/dashboard.mp4",
    "Dashboard-Reservations":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Reservation+dashboard.mp4",
    "Activity":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Activitytoday+activity.mp4",
    "Employee-Management":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/employee.mp4",
    "Subscription-Management":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/subscription+managment.mp4",
    "Property-Management":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/property+managment.mp4",
    "Policy-Management":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/policy+management.mp4",
    "Amenities-Status":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/amenities+status.mp4",
    "Hotel-Amenities":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/hotel+amenities.mp4",
    "Room-Type":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/room-type.mp4",
    "Rates":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/rates.mp4",
    "Room-Details":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/room+details.mp4",
    "Room-Availability":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/room+availbility.mp4",
    "Room-Reservation":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room+Reservation.mp4",
    "Room-Move":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room+Move.mp4",
    "Calendar-View":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Calendar.mp4",
    "Amenities-Reservation":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Ameniites+Reservation.mp4",
    "Payment-Reconciliation":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Payment+Reconcillation.mp4",
    "Payment-Reportt":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Paymet-Reports.mp4",
    "Production-Reports":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Production-Report.mp4",
    "Financial-Reports":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Financial-Report.mp4",
    "Account-Balances":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Account-Balances.mp4",
    "Arrivals":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Arrivals.mp4",
    "Departures":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Departure.mp4",
    "In-House":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Inhouse.mp4",
    "Room-Assignments":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room-Assignments.mp4",
    "Notes":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Notes.mp4",
    "Daily-Revenue":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Daily-Revenue.mp4",
    "room_move":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room-move.mp4",
    "Police":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/police_reports.mp4",
    "Sell-Inventory-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Sell+Inventory+Report.mp4",
    "Room-Status-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room+Status.mp4",
    "Daily-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/daily+report.mp4",
    "Daily-Collection-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/daily-collection.mp4",
    "Daily-Collection-Payment":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/daily+collection+payment.mp4",
    "Buy-Inventory":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/buy_inventory.mp4",
    "Sell-Inventory":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/sell_inventory.mp4",
    "Role Assign":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Manage+Roles.mp4",
    "Daily-Summary":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Daily+Summary+Report.mp4",
    "Ledger-Summary":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Ledger+Summary+Report.mp4",
    "Payment-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Payment+Report.mp4", 
    "Permission Management":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Permission+Management.mp4",
    "Room-Change-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Room+Move+Report.mp4",
    "Amenities-Report":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/Amenities+Report.mp4",
    "Edit Property":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/property+managment.mp4",
    "Add Policy":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/policy+management.mp4",
    "Edit Policy":"https://hotelbucket.s3.ap-south-1.amazonaws.com/videos/hotel-react-ui+videos/aws+upload+video/policy+management.mp4",

    
}

export default VideoLink