export const InfoDetail = {
  'Payment Report': 'This Report includes revenue generated by the Hotel',
  'Account Balances': 'Account balances track guest expenses and facilitate accurate billing and payment Information',
  Arrivals: 'Arrivals Reports refers to guests who checked in on specific date',
  'Production Reports':
    'This Report includes RevPar-revenue generated by the hotel, Occupancy, Average Daily, Room type sales summary and Country-wise checked-in guests .',
  Departures: 'Departure Reports refers to guests who checked out on specific date',
  'In House': 'This Reports lists all guests who are currently checked in,including additional guests',
  'No Show': 'This report refers to reservations that were marked as no show due to guest non-arrival',
  Cancellations: 'Cancellation reports refers to records that detail reservations or bookings that have been cancelled by guests',
  'Room Assignments': 'This Report suggests that which guests are assigned to specific Rooms',
  Notes: 'Notes offers flexibility for viewing notes entered for reservations or guests',
  'User Reconciliation': 'User Reconcillation Reports refers to paymentactivity for specific user',
  'Daily Revenue': 'Daily revenue provides transactions and departmental breakdowns.',
  'Room Change Report':
    'This report provides a comprehensive overview of the room relocations that guests have undergone. It presents detailed information about the changes in their accommodations.',
  
    Police: 'This report displays verified guest details for security and goverment procedure.',
  'Sell Report': "This report tracks purchases made by the guests or other's from hotel's inventory.",
  'Financial Reports':
    'This Report includes reports on commission, commission generated by the hotel, daily statistics, Adjustments, ledger, invoices and tax breakdown of payment by the guest.',
   'Daily Report': 'This Report includes reports on Booking Id, Room, Guest Name, Check-In, Check-Out, Status, Discount, Security, Extra Tax, Payment',
   'Daily Shift Report': 'This Report contains info about what rooms were sold in the shifts of all individual employees', 
   'Daily Collection Report': 'This Report includes reports on Booking Id, Room, Guest Name, Date, Check-In, Check-Out, Employee, Payment, Payment Type', 
   'Daily Collection Payment': 'This Report includes reports on Booking Id, Room, Guest Name, Date, Check-In, Check-Out, Payment, Payment Type, Stripe, Card', 
  'Ledger Summary' : 'This Report includes reports on Cash Ledger Summary, Accounts Ledger Summary, Guest Ledger Summary, Advance Booking Summary & Deposit Ledger Summary.',
  'Daily Summary' : 'This Report includes reports on Rent & Occupany Tax, Other Charges, Payment, Authorized Payments, Deposits, Business Source Analysis, Room Status & Statistics.', 
  'Employee': 'In this page, manage employees effortlessly. Add, view, and edit employee details seamlessly for efficient team management.',
  'Subscription View':
    'In this page, manage subscriptions easily. Add, view, and modify subscribers accordingly. Check existing subscriptions hassle-free.',
  'Property Details': "Here explore images and location information for a comprehensive view of the property's features and surroundings.",
  'Guest Policy': 'Welcome to the Guest Policy page, where guidelines and regulations ensure a pleasant experience for all visitors.',

  'Permission Management':
    'Welcome to the Permission Management Page! Here, you can assign specific access rights to different roles. Customize permissions, manage user privileges, and ensure secure access to features based on roles.',
  'Role Assign':
    'Manage Roles Page allows you to oversee and organize user roles within the system. Create, modify, or delete roles as needed. Ensure efficient role allocation for effective user management.',

  'Buy Inventory':'Welcome to the Buy Inventory Page! Here, you can purchase items for your inventory effortlessly. Browse, select, and add items to your stock.',
  'Sell Inventory':'Explore the Sell Inventory Page! List and sell items from your inventory seamlessly. Manage listings, set prices, and monitor sales.',

  'Room Reservation': 'Room Reservation allows you to book rooms for specific dates. Choose your check-in and check-out dates, view available rooms, select a room, enter guest details, and proceed with secure payment to confirm your reservation.',
  'Room Move': 'Room Move feature enables you to manage room transfers. Review details of room shifts, including guest movement from one room to another, to ensure a smooth transition during their stay.',
  'Calendar View': 'Calendar View provides a visual representation of bookings and reservations. View and manage bookings by clicking on calendar entries to access detailed information for efficient scheduling.',
  'Amenities Reservation': 'Amenities Reservation lets you book facilities and services. Choose your desired time mode (hourly or daily), select dates, view available amenities, add amenities to your reservation, provide guest details, and proceed to payment for a hassle-free experience.',
  'Payment Reconciliation': 'Payment Reconciliation allows you to track and review all payment-related details. Access comprehensive records of transactions, ensuring accuracy and transparency in financial dealings.',
  'Add Amenities':'User can add new amenities with help of this page, please provide the necessary information for each amenity, including the name, rent per day, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!',
  'Edit Amenities':"Users can edit existing amenities using this page. Please provide the necessary information for each amenity that you would like to change, including the name, rent per day, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Dashboard":"Explore real-time arrivals, departures, reservations, and revenue on our dynamic dashboard for seamless management. Manage bookings, check-in/out, and view guest details conveniently on the Reservation page. Today's Activity: View bookings, room nights, and revenue. Filter by date and access comprehensive details at a click.",
  "Amenities Status":"This section allows you to track the status of various amenities within our establishment. You can check the availability, reservation status, and historical data for different facilities.",
  "Hotel Amenities":"Here, discover our Hotel Amenities section! Here, you'll find detailed information about the various amenities we offer.",
  "Room Type":"The Room Type section allows you to manage different types of rooms available in the system. Here, you can add, edit, or delete room types. Each room type includes details such as room category, capacity, and any specific amenities associated with it",
  "Rates":"In the Rates section, you can set and modify rates for different room types. This includes configuring short-term rates and yearly rates for each room type. You can add, edit, or delete rates based on specific time frames or durations",
  "Room Details":"The Room Details section provides comprehensive information about individual rooms. You can view and manage specific room details, including room number, status, associated room type, and any additional notes or descriptions related to the room",
  "Room Availability":"The Room Availability Page displays a summary of all available rooms within the system. It allows you to quickly check the current availability status of rooms, view their details, and manage their availability for bookings or reservations. You can easily navigate through rooms to assess their occupancy status and other relevant information",
  "Room View":"The Room View Page provides an overview and interactive display of all available rooms within the system.",
  "Guest Details":"Here you can get the overview of the bookings related information",
  "Edit Property" :"Here user can Edit the details for the particular property.",
  "Add Policy" : "User can able to add new polices",
  "Edit Policy" : "User can able to Edit Policies and Delete Policies",
  "Property Management":"Here user can view the details of the property selected",
  "Policy Management":"Here user can view all the policies of the property selected",
  "Employee Management" :"All details of the Employee are visible.",
  "Subscription Management":"Here you can get the overview of guest related information.",

  "Amenities Report":"Amenities report refers to guests who have booked amenities for specific dates. Users can use filters to search for specific data.",
  "Room Status Report":"The Room Status report shows the current room status. Users can use filters to search for specific data.",
  "Add Room Type":"Users can add a new Room Type with the help of this page. Please provide the necessary information for each Room Type, including the room type, number of beds, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Edit Room Type":"Users can edit existing Room types using this page. Please provide the necessary information for each Room Type that you would like to change, including the Room Type, number of beds, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Base Rates":"Users can add a new Rate Plan with the help of this page. Please provide the necessary information for the rate plan, such as interval name, start date, end date, and any other relevant details. This will help ensure that the information is accurate and complete. Thank you!",
  "Reservations":"Users can view the current booking status of guests with the help of this page.",
  "Activity":"Users can view the current booking revenue, total number of bookings, and other related information with the help of this page.",
  "Occupancy Status":"Users can view the occupancy status of rooms with the help of this page.",
  "Cancellation Policy":"Users can view the policy regarding the cancellation of booking.",
  "Cancellation":"This page provides an insight for the users who want to cancel their booking",
  "Daily Deposit Report":'The daily deposit report provides a comprehensive overview of all financial transactions and incoming revenue for the hotel, ensuring transparency and accountability',
  "Manage Roles":"Manage Roles typically refers to the administrative task of assigning, modifying, or removing roles or permissions within a system or organization.",
  "Extend Stay":'The "extend stay" feature in a hotel management system enables guests to prolong their stay without the need for manual rebooking, streamlining the process for both guests and hotel staff.',
  "Extend Stay Process":"The Extend Stay Process refers to the procedure by which guests prolong their stay at a hotel beyond their original checkout date. This typically involves guests requesting an extension through the hotel's front desk or via the hotel management system.",
  "Registration Form":'The "Registration Form" feature in a hotel management system enables guests to view thier booking details , Personal Information regarding the stay in their hotel',
  "Payment Form":'The "Payment Form" feature in a hotel management system enables guests to view their booking details , Personal Information and Payment Details for Daily Charging View.',
  "Refund":'The "Refund" feature in a hotel management system enables employees to make refund for the payment initiated'
};
export default InfoDetail;
