import React from 'react';
import { Accordion, Form, Modal, Button, Row, Col } from 'react-bootstrap';
import adminLayout from '../../hoc/adminLayout';
import { useState, useEffect } from "react";

import '../../assets/css/form_x.css';
import CloseButton from '../Common_Fun/CloseButton';

const RoomAllot = (props) => {
  const [roomType, setRoomType] = useState({});
  const [selectRoom, setSelectRoom] = useState({});
  const [disableBtn, setDisableBtn] = useState(false)
  const shortName = {"Available":"(A)","V/Dirty":"(D)","Occupied":"(O)","available":"(A)","v/dirty":"(V)","occupied":"(O)"}
  const color = {"Available":"av","V/Dirty":"um","Occupied":"oc","available":"av","v/dirty":"um","occupied":"oc"}
  useEffect(() => {
    console.log("slslsl",props.selectRoom)
    const SL = {}
    Object.keys(props.totalRoom).map((roomT)=>{
      SL[roomT] = []
    })
    setSelectRoom(SL)
    // const sortedRooms = {};
    //     Object.keys(props.selectRoom).map(key => {
    //         sortedRooms[key] = props.selectRoom[key].sort((a, b) => a - b);
    //     });
    setRoomType(props.selectRoom)
    
  }, [props.show_room]);

  const handleClose = () =>{
    if(props.setState !== undefined){
      props.setState({ show_room: false })
    }else{
      props.setShow_room(false)
    }
   };

   const select = (room, type, check) => {
    setSelectRoom((prevState) => {
      const selected = prevState[type] ? [...prevState[type]] : [];
      const index = selected.indexOf(room);
  
      if (check === "select") {
        if (index === -1) {
          selected.push(room);
        }
      } else {
        if (index > -1) {
          selected.splice(index, 1);  
        }
      }
  
    
      const len1 = Object.values(props.totalRoom).reduce((acc, arr) => acc + arr.length, 0);
      const len2 = Object.values({ ...prevState, [type]: selected }).reduce((acc, arr) => acc + arr.length, 0);
  
      setDisableBtn(len1 === len2);
  
      return {
        ...prevState,
        [type]: selected,
      };
    });
  
    setRoomType((prevState) => {
      const selected = prevState[type] ? [...prevState[type]] : [];
  
      if (check === "select") {
        if (selected.indexOf(room) === -1) {
          selected.push(room);
        }
      } else {
        if (selected.indexOf(room) === -1) {
          selected.push(room);
        }
      }
  
      return {
        ...prevState,
        [type]: selected, 
      };
    });
  };
  
  

  const confirm = () =>{
    console.log("select Room", selectRoom)
    props.finalSelect(selectRoom)
    if(props.setState !== undefined){
      props.setState({ show_room: false })

    }else{
      props.setShow_room(false)
    }
   
    
    
  }
  

  return (
    <div >
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}
      <Modal
        className="roomModal"
        show={props.show_room}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Stay Room(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.oldRoom !==undefined && <div className='mb-2'>
            <h5 style={{color: "#0747A6"}}>Currently Alloted Room(s)</h5>
            <hr color='#a9a9a9'/>
            {Object.keys(props.oldRoom).map((roomType)=>{return<div className='mx-1 row mt-3'>
            
            <div className='col-2'><h6>{roomType}</h6></div>
              {props.oldRoom[roomType].map((room)=>{return <div className='col-2'>{room}</div> })}
              </div>
            
            })}
            </div>}
            <h5 style={{color: "#0747A6"}}>Select new room</h5>
            <hr color='#a9a9a9'/>

          <Accordion alwaysOpen>
            {Object.keys(roomType).map((type) => (
              <Accordion.Item eventKey={type} key={type}>
                <Accordion.Header className='accordion-head'>{type}</Accordion.Header>
                <Accordion.Body style={{maxHeight:"200px",overflowX:"auto"}}>
                  <div className="row">
                    <div className="col-2">Selected Room</div>
                    <div className="col-10 checkbox-grid">
                      {selectRoom[type].sort((a, b) => a - b).map((room) => (
                        <Form.Check
                          key={room}
                          inline
                          title={props.roomStatus[room]==="Occupied"? `${props.roomStatus[room]} yet to be checked-out`:props.roomStatus[room]}
                          // style={{color:`${color[shortName[props.roomStatus[room]]]}`}}
                          className={`${color[props.roomStatus[room]]}`}
                          onChange={() => select(room, type, "notSelect")}
                          type={"checkbox"}
                          id={`selected-checkbox-${room}`}
                          label={<div className='gapinroomstatus'>
                            <span>{room}</span>
                            <span className='onlystatuscolor'>{shortName[props.roomStatus[room]]}</span>
                            </div>}
                          checked={true}
                        />
                      ))}
                    </div>
                  </div>
                  <hr style={{border: "0", borderBottom: "2px dashed #eee"}} />
                  <div className="row">
                    <div className="col-2">Select Room(s) ({roomType[type].length})</div>
                    <div className="col-10 checkbox-grid">
                      {roomType[type].sort((a, b) => a - b).map((room) => (
                        <Form.Check
                          key={room}
                          title={props.roomStatus[room]==="Occupied"? `${props.roomStatus[room]} yet to be checked-out`:props.roomStatus[room]}
                          inline
                          className = {`${color[props.roomStatus[room]]}`}
                          disabled={(props.totalRoom[type] !== undefined && props.totalRoom[type].length <= selectRoom[type].length) }
                          onChange={() => select(room, type, "select")}
                          type={"checkbox"}
                          id={`room-checkbox-${room}`}
                          label={<div className='gapinroomstatus'>
                            <span>{room}</span>
                            <span className='onlystatuscolor'>{shortName[props.roomStatus[room]]}</span>
                            </div>}
                          checked={selectRoom[type].includes(room)}
                        />
                      ))}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Modal.Body>
        <Modal.Footer style={{display:"flex",justifyContent:"center"}}>
        <CloseButton  onClick={()=>handleClose()}/>
          <Button onClick={()=>{confirm()}} disabled={!disableBtn} variant="primary">Confirm</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RoomAllot;
