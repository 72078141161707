import React, { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import Css from "./../assets/css/sidebar.module.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, json, useNavigate } from "react-router-dom";
import SidebarData from "../Pages/Constants/SidebarData";
import ChildSidebar from "../Pages/Constants/ChildSidebar";
import ConvertLatter from "../Pages/Common_Fun/ConvertLatter";
import AlertDismissible from "../Pages/Common_Fun/AlertDismissible";
import loacalAvatar from "./../assets/images/user-profile.png";
import bullet from "./../assets/images/bullet.png";
import sideBarImg from "./../assets/images/BK2.png";
import SubSidebar from "../Pages/Constants/SubSidebar";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "../../src/assets/css/sidebar.css";
import workspace_premium from "./../assets/images/workspace_premium.png";
import dash_board from "./../assets/images/dashbord.png";
import { BsColumnsGap } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { FaHotel } from "react-icons/fa6";
import Skeletonloader from "../components/skeletonloader/Skeletonloader";

import { redirect } from "react-router-dom";
import ApiCall from "../Pages/Constants/ApiCall";

const Sidebar = (props) => {
  const [userpermissions, setUserpermissions] = useState("");
  const [show_data, setShow_data] = useState("no");
  const [loader, setLoader] = useState("yes");
  const [showSuccess, setShowSuccess] = useState(false);
  const url = `${props.url}sidebardata/`;
  //const [inventory,setInventory] = useState('no')
  const [sidebarData, setSidebarData] = useState({});
  const [dashShow, setDashShow] = useState("yes");
  const [proImage, setProimage] = useState();
  const [error, setError] = useState("");
  const [area, setArea] = useState({});
  const [sidebarNestedIndex, setSideBarNestedIndex] = useState([]);
  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage?.getItem("collapsed"))
  );
  const [propertyData, setPropertyData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [logo, setLogo] = useState('')


  const CheckVar = ["__send", "__edit", "__delete", "__get"];
  // console.log("propertyDatapropertyData", propertyData);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };



  const [isActiveOption, setIsActiveOption] = useState(null);

 

  const get_properties = (data) => {
    const url = `${props.url}list_properties`;
    const token = JSON.parse(localStorage.getItem("logintoken"));
    try {
     
      
          let code, redirect;
          let dict = {};
          data.map((d) => {
            dict[d.name] = true;
            code = d.code;
            redirect = d.redirect;
          });
          // console.log("Object.keys(data).length>>", Object.keys(data).length);
          // this.setState({ dataLength: Object.keys(data).length });
          if (Object.keys(data).length < 1) {
            // If there are no properties that means probably the auth string is wrong
            let blocks = window.location.href.split("/"); // In that case redirect to login directly
            // If the client has no properties, how can they login?
            //window.location.href = `${blocks[0]}//${blocks[2]}/login` 
           handleSignOut();
          }
          else if(Object.keys(data).length==1){
            Confirm(redirect, code);
            setPropertyData(data);
            const newStoragename = localStorage.getItem('roleName')
            const matchedProperty = data?.find(items => items.name === newStoragename);
            localStorage.setItem("address", JSON.stringify(matchedProperty?.address));

            //redirect("/login");
          } else {
            const newStoragename = localStorage.getItem('roleName')
            const matchedProperty = data?.find(items => items.name === newStoragename);
            localStorage.setItem("address", JSON.stringify(matchedProperty?.address));

            // this.setState({ checkCon: dict });
            // this.setState({ property: data });
            setPropertyData(data);
          }
        
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    Object.keys(SubSidebar).map((value) => {
      area[value] = false;
    });
    const data = sessionStorage.getItem("profilePictureUrl");
    if (data === "undefined") {
      setProimage(loacalAvatar);
    } else {
      setProimage(sessionStorage.getItem("profilePictureUrl"));
    }
    
  }, []);


  console.log(logo,'logo')


  const checkToken = () => {
    if (localStorage.getItem("logintoken")) {
      // get token and do anything
      if (
        localStorage.getItem("property") === undefined ||
        localStorage.getItem("property") === "" ||
        localStorage.getItem("property") === "error"
      ) {
        localStorage.removeItem("logintoken");
        window.location.href = "/login";
      }
    }
  };

  const extend = (subSide) => {
    setArea((prevArea) => ({
      ...prevArea,
      [subSide]: !prevArea[subSide],
    }));
  };
  // console.log(area);
  const CheckSide = (user_permission) => {
    let detailVal;
    let childKey;
    let childShow;
    let replace;
    let already = {};
    let all_data = {};
    const sidebar_data = {};
    let current_url = window.location.protocol + "//" + window.location.host;
    const location = window.location.href;
    SidebarData.map((data) => {
      all_data[`${data.heading}_arr`] = [];
      sidebar_data[data.heading] = "no";
      Object.keys(data.subheading).map((subhead_data) => {
        detailVal = ConvertLatter(data.subheading[subhead_data][0], "first");

        all_data[`${data.heading}_arr`].push(
          ConvertLatter(data.subheading[subhead_data][0], "first")
        );
       
        if (
          
          ConvertLatter(data.subheading[subhead_data][0], "first") in
          user_permission
        ) {
          sidebar_data[data.heading] = "yes";
         
          if (data.heading in ChildSidebar) {
            if (detailVal in ChildSidebar[data.heading]) {
              childShow = ChildSidebar[data.heading][detailVal];
              childKey = Object.keys(childShow);
              if (!(detailVal in already)) {
                for (let val in childShow) {
                  already[detailVal] = true;
                 
                  if (val.includes("__send")) {
                    if (!user_permission[detailVal].includes("store")) {
                      replace = val.replace("__send", "");
                      
                      if (location.includes(`${current_url}/${replace}`)) {
                        navigate("/");
                      }
                    }
                  }
                  if (val.includes("__edit")) {
                    if (!user_permission[detailVal].includes("edit")) {
                      replace = val.replace("__edit", "");

                      if (location.includes(`${current_url}/${replace}`)) {
                        navigate("/");
                      }
                    }
                  }
                  if (val.includes("__delete")) {
                    if (!user_permission[detailVal].includes("delete")) {
                      replace = val.replace("__delete", "");
                      if (location.includes(`${current_url}/${replace}`)) {
                        navigate("/");
                      }
                    }
                  }
                  if (val.includes("__get")) {
                    if (!(detailVal in user_permission)) {
                      replace = val.replace("__get", "");
                      if (location.includes(`${current_url}/${replace}`)) {
                        navigate("/");
                      }
                    }
                  }
                }
              }
            }
          }
          
         
        } else {
          
          if(!subhead_data?.includes('/')){
            if(`${current_url}/${subhead_data}` === location){
              navigate('/')
            }
          }
          else{
            if (location.includes(`${current_url}/${subhead_data}`)) {
              navigate("/");
            }
          }
           
          
       
         
        }
      });
    });

    setSidebarData(sidebar_data);
    setUserpermissions(user_permission);
    // sessionStorage.setItem('SidebarData',JSON.stringify(sidebar_data))
  };
 

  function areObjectsDifferent(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of properties is different
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Check if the values of each property are different
    for (const key of keys1) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (!arraysAreEqual(obj1[key], obj2[key])) {
          return true;
        }
      } else if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
    return false;
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i in arr2) {
      if (!arr1.includes(arr2[i])) {
        return false;
      }
    }
    return true;
  }

  const update_data = async () => {
    checkToken();

    let user_permission;
    let parse, parseStore;
    let StorageUser;
    StorageUser = localStorage.getItem("user_permission");
    let StorageSide = sessionStorage.getItem("SidebarData");
    if (StorageUser != null) {
     
      if (StorageSide == null) {
        parse = JSON.parse(StorageUser);
        
      } else {
        
        parse = JSON.parse(StorageUser);
        parseStore = JSON.parse(StorageSide);
      }
    }
    let a = JSON.parse(localStorage.getItem("logintoken"));
    if (a !== null) {
      let property = localStorage.getItem("property");
      let data = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + a["token"],
        },
        body: JSON.stringify({
          property: property,
        }),
      });
      let user_permissions = await data.json();
      // const nameSkeletonLoader = sessionStorage.getItem('name');
      // if (nameSkeletonLoader != null) {
      //   setLoader("no");
      // }
      // console.log(user_permissions);
      let url_this = window.location.protocol + "//" + window.location.host;
      const location_local = window.location.href;
      if (user_permissions.dash === "no") {
        if (!location_local.includes(`${url_this}/profile`)) {
          sessionStorage?.setItem('redirect','no')
          navigate("/profile");
        }
      }
      if (user_permissions.show === "no") {
        if (location_local.includes(`${url_this}/Manage-Roles`)) {
          navigate("/");
        }
        if (location_local.includes(`${url_this}/Permission-Management`)) {
          navigate("/");
        }
      }

      if (user_permissions.code === "token_not_valid") {
        localStorage.removeItem("logintoken");
      }
      if (data.code === "user_not_found") {
        localStorage.removeItem("logintoken");
      }
      //
      setShow_data(user_permissions.show);
      setDashShow(user_permissions.dash);
      setLogo(user_permissions?.final_logo)
      get_properties(user_permissions.properties);
      let permissions = user_permissions.user_permissions;
      if (permissions === "undefined") {
        return setError("Pls add permission to view other pages");
      } else {
        if (permissions) {
          user_permission = permissions;
        }
      }

      //let all_option = [optin_arr,rooms_arr,reservation_arr,reports_arr]
      let check;
      let string = JSON.stringify(user_permission);
      if (StorageUser !== null) {
        if (user_permission) {
          check = areObjectsDifferent(user_permission, parse);
        } else {
          check = areObjectsDifferent({}, parse);
        }
        if (check) {
          if (user_permission) {
            CheckSide(user_permission);
            localStorage.setItem("user_permission", string);
          }
        }
      } else {
        CheckSide(user_permission);
        localStorage.setItem("user_permission", string);
      }

     

    }
  };


 

  
  const navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.removeItem("logintoken");
    localStorage.removeItem("property");
    localStorage.removeItem("collapsed");
    localStorage.removeItem("SidebarData");
    localStorage.removeItem("user_permission");
    sessionStorage.removeItem("profilePictureUrl");
    sessionStorage.removeItem("persistentFormData");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("propertyData");
    sessionStorage.removeItem("SalesData");
    sessionStorage.removeItem("editPolicy");
    sessionStorage.removeItem("AddPolicy");
    sessionStorage.removeItem("propertyDataAdd");
    sessionStorage.removeItem("propertyDataEdit");
    sessionStorage.removeItem("GuestData");
    sessionStorage.removeItem("CheckInData");
    sessionStorage.removeItem("NotesData");
    sessionStorage.removeItem("SidebarData");
    sessionStorage.removeItem("redirect");
    navigate("/login");
  };

  const Confirm = async (redirect = null, code = null, item) => {
    // console.log("redirect = null, code = null, item");

    let a = JSON.parse(localStorage.getItem("logintoken"));
    let data_user = JSON.parse(localStorage.getItem("data"));
    const property = localStorage.getItem("property");
    let id = data_user.userid;
    let urls = `${props.url}userprofile/${property}/${id}/`;
    let dict = {
      option: "change_property",
      property: item?.id.toString(),
    };
    let data = await fetch(urls, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + a["token"],
      },
      body: JSON.stringify(dict),
    });
    
    let user_data = await data.json();
    if (user_data) {
      if(user_data.property === undefined){
        localStorage.removeItem("logintoken")
      }else{
        localStorage.setItem("property", user_data.property);
        localStorage.setItem("roleName", item?.name);
        
    
        setShowSuccess(true);
      }
    
    }
  };
  
  const nameSkeletonLoader = sessionStorage.getItem('name');
  useEffect(() => {
    if (!localStorage.getItem("logintoken")) {
      navigate("/login");
    }
    let parse, parseStore;
    let StorageUser;
    StorageUser = localStorage.getItem("user_permission");
    let StorageSide = sessionStorage.getItem("SidebarData");
    if (StorageUser != null) {
      // setLoader("no");
      parse = JSON.parse(StorageUser);
      CheckSide(parse);
    }

    if (loader === "yes") {
      update_data();
    }
    

    // const nameSkeletonLoader = sessionStorage.getItem('name');
    if (nameSkeletonLoader != null) {
      setLoader("no");
    }
  }, [loader,nameSkeletonLoader]);

  const msgFun = () => {
    setError("");
    if (showSuccess) {
      setShowSuccess(false);
      window.location.reload();
    }
  };
  const toggleSidebar = (name = null) => {
    if (name === "image") {
      localStorage.setItem("collapsed", !collapsed);
    } else {
      localStorage.setItem("collapsed", false);
    }

    if (name === null) {
      setCollapsed(!collapsed);
      if (!collapsed) {
        const mainElement = document.querySelector(".main");
        const fixedTopElement = document.querySelector(".fixed-top");
        const fixedBottomDropdownElement = document.querySelector(
          ".fixed-bottom-dropdown"
        );

        if (mainElement !== null) mainElement.style.marginLeft = "70px";
        if (fixedTopElement !== null) fixedTopElement.style.left = "70px";
        if (fixedBottomDropdownElement !== null)
          fixedBottomDropdownElement.style.width = "70px";
      } else {
        const mainElement = document.querySelector(".main");
        const fixedTopElement = document.querySelector(".fixed-top");
        const fixedBottomDropdownElement = document.querySelector(
          ".fixed-bottom-dropdown"
        );

        if (mainElement !== null) mainElement.style.marginLeft = "200px";
        if (fixedTopElement !== null) fixedTopElement.style.left = "240px";
        if (fixedBottomDropdownElement !== null)
          fixedBottomDropdownElement.style.width = "220px";
      }
    } else if (name === "image") {
      setCollapsed(!collapsed);
      if (!collapsed) {
        const mainElement = document.querySelector(".main");
        const fixedTopElement = document.querySelector(".fixed-top");
        const fixedBottomDropdownElement = document.querySelector(
          ".fixed-bottom-dropdown"
        );

        if (mainElement !== null) mainElement.style.marginLeft = "70px";
        if (fixedTopElement !== null) fixedTopElement.style.left = "70px";
        if (fixedBottomDropdownElement !== null)
          fixedBottomDropdownElement.style.width = "70px";
      } else {
        const mainElement = document.querySelector(".main");
        const fixedTopElement = document.querySelector(".fixed-top");
        const fixedBottomDropdownElement = document.querySelector(
          ".fixed-bottom-dropdown"
        );

        if (mainElement !== null) mainElement.style.marginLeft = "200px";
        if (fixedTopElement !== null) fixedTopElement.style.left = "240px";
        if (fixedBottomDropdownElement !== null)
          fixedBottomDropdownElement.style.width = "220px";
      }
    }
    // Toggle the collapsed state
  };
  console.log(propertyData,'sidebarcheck')
  useEffect(() => {
    const a = localStorage?.getItem("collapsed");
    const b = JSON.parse(a);

    // Check if the elements exist before attempting to manipulate them

    if (collapsed) {
      document.querySelector(".main").style.marginLeft = "70px";
      // document.querySelector('.navbar').style.left = '70px';
      // document.querySelector('nav.navbar').style.left='70px';

      document.querySelector(".fixed-bottom-dropdown").style.width = "70px";
      // fixedTopElement.style.left = '70px';
      // fixedBottomDropdownElement.style.width = '70px';
    } else {
      document.querySelector(".main").style.marginLeft = "220px";
      // document.querySelector('nav.navbar').style.left='240px';
      document.querySelector(".fixed-bottom-dropdown").style.width = "240px";
      // mainElement.style.marginLeft = '200px';
      // fixedTopElement.style.left = '240px';
      // fixedBottomDropdownElement.style.width = '200px';
    }
  }, [collapsed]);
  // console.log(sidebarData);
  // const nameSkeletonLoader = sessionStorage.getItem('name');
  // console.log(nameSkeletonLoader,'nameeeeeee');
  return (
    <div className={`sidebar-container ${collapsed ? "collapsed" : ""}`}>
      {error && (
        <AlertDismissible
          msgFun={msgFun}
          time={6}
          type={"danger"}
          msg={error}
        />
      )}
      {showSuccess && (
        <AlertDismissible
          msgFun={msgFun}
          type={"success"}
          msg={`Property Changed Successfully `}
        />
      )}
      <div
        style={{ padding: 10 }}
        className={`border-end sidenav ${collapsed ? "collapsed" : ""}`}
        id="sidebar-wrapper"
      >
        {collapsed ? (
         ( loader === "no" ? ( <img
            onClick={() => toggleSidebar("image")}
            alt="Logo"
            src={logo}
            style={{
              color:'white',
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              marginTop: "25px",
            }}
            
          />):(
             <Skeletonloader variant={"circular"} width={"50px"} height={50} borderRadius={45} />
          )) ) : ("")}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          className="sidebar-heading border-bottom "
        >
          {collapsed ? (
            ""
          ) : (
             
              <Link
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "40px",
              }}
            >
              { loader ==="no"?(<img
                alt="Logo"
                src={logo}
                // style={{ width: "65px", height: "65px" }}
                className={Css.logo}
              />):(
                <Skeletonloader variant={"circular"} width={"55px"} height={50} borderRadius={45} marginLeft={40}/>

              )}
              {loader ==="no"? (<h1 style={{ fontSize: "25px", margin: 0 }}>Gogarnet</h1>):(<Skeletonloader variant={"rectangular"} height={25} width={"100px"} borderRadius={5} marginLeft={25} />)}
            </Link>
            
        )
          }
          {collapsed ? (
            <div
              style={{
                position: "absolute",
                top: -60,
                left: -4,
              }}
            >
              <KeyboardDoubleArrowRightIcon
                onClick={() => toggleSidebar("image")}
                style={{ color: "wheat" }}
              />
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: -1,
              }}
            >
              <KeyboardDoubleArrowLeftIcon
                onClick={() => toggleSidebar("image")}
                style={{ color: "wheat" }}
              />
            </div>
          )}
        </div>
        {dashShow === "yes" && (
          <PerfectScrollbar
            className="sidebar-items"
            style={collapsed ? { overflowY: "hidden" } : {overflowX:"hidden"}}
          >
            <ul
              className="list-unstyled ps-0"
              style={collapsed ? { overflowY: "hidden" } : {overflowX: "hidden"}}
            >
              <li className="mb-1">
                <Link tag="a" className="dashboard-text" to="/">
                 {loader==="no"?(<img
                    src={dash_board}
                    style={{
                      height: "19px",
                      width: "19px",
                      marginTop: "5px",
                      marginLeft: -5,
                    }}
                    alt="dashboardtext"
                  />):(
                    <Skeletonloader variant="circular" height={20} width="20px" borderRadius={20} marginLeft={-5} />
                  )}
                  <span style={{ fontSize: "13px", paddingLeft: "19px" }}>
                    {" "}
                    {collapsed ? "" : (loader === "yes" ? <Skeletonloader variant="rectangular" height={15} width="100px" borderRadius={5} marginLeft={0} /> : "Dashboard")}
                    </span>
                </Link>
              </li>
              {SidebarData.map((options, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      display: collapsed ? "flex" : "",
                      justifyContent: collapsed ? "center" : "",
                    }}
                    className="mb-1"
                  >
                    {sidebarData[options.heading] === "yes" && (
                      <>
                        <button
                          onClick={() => {
                            if (!collapsed) {
                              toggleSidebar("n");
                            } else {
                              toggleSidebar();
                            }

                            setIsActiveOption(
                              isActiveOption === index ? -1 : index
                            );
                          }}
                          style={{
                            fontSize: "12px",
                            paddingLeft: "0",
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "start",
                            width: "100%",
                            alignItems: "center",
                          }} // Adjust padding as needed
                          className={`btn ${collapsed ? "" : "btn-toggle"} ${
                            isActiveOption === index ? "activeOption" : ""
                          } align-items-center rounded collapsed,`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#dashboard_${index}`}
                          aria-expanded="false"
                          title={collapsed ? options?.heading : ""}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "start",
                              width: "100%",
                            }}
                          >
                           {loader==="no"?(<img src={options.icon} id={options.heading} alt='sidebarheading' />):(<Skeletonloader variant="circular" height={20} width="20px" borderRadius={20} marginLeft={0} />)}
                            {/* Icon */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: "10px",
                              }}
                            >
                              <span>{collapsed ? "" : (loader === "yes" ? <Skeletonloader variant="rectangular" height={15} width="100px" borderRadius={5} marginLeft={0} /> : options.heading)}</span>{" "}
                              {/* Heading */}
                              {/* <span> */}{" "}
                              {!collapsed && loader === "no" && (
                                <span
                                  style={{
                                    transition: "transform 0.3s ease",
                                    transform:
                                      isActiveOption === index
                                        ? "rotate(180deg)"
                                        : "rotate(90deg)",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <IoIosArrowUp />
                                </span>
                              )}
                              {/* </span> */}
                            </div>
                          </div>
                        </button>
                        <div className="collapse" id={`dashboard_${index}`}>
                          <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {collapsed ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                {Object.keys(options.subheading).map(
                                  (sub_option, count) => {
                                    return (
                                      <div key={count}>
                                        {userpermissions[
                                          ConvertLatter(
                                            options.subheading[
                                              Object.keys(options.subheading)[
                                                count
                                              ]
                                            ][0],
                                            "first"
                                          )
                                        ] && (
                                          <li >
                                            {!Object.values(SubSidebar)
                                              .flat()
                                              .includes(sub_option) && (
                                              <Link
                                                to={`/${
                                                  Object.keys(
                                                    options.subheading
                                                  )[count]
                                                }`}
                                                className="rounded"
                                              >
                                                {ConvertLatter(
                                                  sub_option,
                                                  "remove"
                                                )}
                                              </Link>
                                            )}
                                          </li>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}

                            {collapsed ? (
                              ""
                            ) : (
                              <>
                                {Object.keys(SubSidebar).map((subSide, ind) => {
                                  return (
                                    SubSidebar[subSide].some(
                                      (value) =>
                                        options.subheading[value] &&
                                        userpermissions[
                                          ConvertLatter(
                                            options.subheading[value][0],
                                            "first"
                                          )
                                        ]
                                    ) && (
                                      <li key={ind}>
                                        <div
                                          className={Css.marked}
                                          style={{
                                            background:
                                              area[subSide] && "#668EF8",
                                            width: "85%",
                                          }}
                                        >
                                          <button
                                            style={{
                                              fontSize: "12px",
                                              color: "white",
                                              width: "80%",
                                              textAlign: "left",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            className="btn rounded collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#sub_${ind}`}
                                            aria-expanded="false"
                                            onClick={() => {
                                              extend(subSide);
                                              setSideBarNestedIndex((prev) => {
                                                const indexExists =
                                                  prev.indexOf(ind) !== -1;
                                                if (indexExists) {
                                                  return prev.filter(
                                                    (item) => item !== ind
                                                  );
                                                } else {
                                                  return [...prev, ind];
                                                }
                                              });
                                            }}
                                          >
                                            <span>{subSide}</span>
                                            <span
                                              style={{
                                                transition:
                                                  "transform 0.3s ease",
                                                transform:
                                                  sidebarNestedIndex?.includes(
                                                    ind
                                                  )
                                                    ? "rotate(180deg)"
                                                    : "rotate(90deg)",
                                                alignSelf: "flex-end",
                                              }}
                                            >
                                              <IoIosArrowUp />
                                            </span>
                                          </button>
                                        </div>
                                        <div
                                          className="collapse"
                                          id={`sub_${ind}`}
                                        >
                                          <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            {Object.keys(
                                              options.subheading
                                            ).map((sub_option, count) => {
                                              return (
                                                <li key={count}>
                                                  {collapsed ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      {userpermissions[
                                                        ConvertLatter(
                                                          options.subheading[
                                                            sub_option
                                                          ][0],
                                                          "first"
                                                        )
                                                      ] &&
                                                        SubSidebar[
                                                          subSide
                                                        ].includes(
                                                          sub_option
                                                        ) && (
                                                          <Link
                                                            to={`/${
                                                              Object.keys(
                                                                options.subheading
                                                              )[count]
                                                            }`}
                                                            className="rounded"
                                                          >
                                                            <img
                                                              className={
                                                                Css.bullet
                                                              }
                                                              src={bullet}
                                                              alt="bullet"
                                                            />

                                                            {sub_option ===
                                                            "Room-Change-Report"
                                                              ? "Room Change"
                                                              : ConvertLatter(
                                                                  sub_option,
                                                                  "remove"
                                                                )}
                                                          </Link>
                                                        )}
                                                    </>
                                                  )}
                                                </li>
                                              );
                                            })}

                                            {/* {userpermissions['permission'] && <li><Link to={'/permission'} className="rounded">permission</Link></li>} */}
                                          </ul>
                                        </div>
                                      </li>
                                    )
                                  );
                                })}
                              </>
                            )}
                            {/* <li><Link to={'/employee'} className="rounded">Employees</Link></li> */}
                          </ul>
                        </div>
                      </>
                    )}
                  </li>
                );
              })}

              {show_data === "yes" && (
                <li className="mb-1" style={{ position: "relative" }}>
                  <button
                    onClick={() => {
                      if (!collapsed) {
                        toggleSidebar("n");
                      } else {
                        toggleSidebar();
                      }
                      setIsActiveOption(isActiveOption === -2 ? -1 : -2);
                    }}
                    style={{
                      fontSize: "12px",
                      marginLeft: !collapsed ? -10 : -20,
                      width: "100%",
                    }}
                    className={`btn ${
                      collapsed ? "" : "btn-toggle"
                    } align-items-center rounded collapsed`}
                    data-bs-toggle="collapse"
                    data-bs-target="#dashboard-collapse6"
                    aria-expanded="false"
                    title={collapsed ? "Roles and Permission" : ""}
                  >
                    
                    {loader==="no"?(<img alt="roles" src={workspace_premium} />):(<Skeletonloader variant="circular" height={20} width="20px" borderRadius={20} marginLeft={2} />)}
                    <span
                      style={{
                        fontWeight: 400,
                        width: "100%",
                        marginLeft: -17,
                      }}
                    >
                      {collapsed ? "" :  (loader === "yes" ? <Skeletonloader variant="rectangular" height={15} width="100px" borderRadius={5} marginLeft={37} /> : "Roles & Permission")}
                      {!collapsed && loader === "no" &&(
                        <span
                          style={{
                            position: "absolute",
                            right: 7,
                            transition: "transform 0.3s ease",
                            transform:
                              isActiveOption === -2
                                ? "rotate(180deg)"
                                : "rotate(90deg)",
                            alignSelf: "flex-end",
                          }}
                        >
                          <IoIosArrowUp />
                        </span>
                      )}
                    </span>
                  </button>
                  {collapsed ? (
                    ""
                  ) : (
                    <div className="collapse" id="dashboard-collapse6">
                      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li>
                          <Link
                            to={"/Permission-Management"}
                            className="rounded"
                          >
                            Permission Management
                          </Link>
                        </li>
                        <li>
                          <Link to={"/Manage-Roles"} className="rounded">
                            Manage Roles
                          </Link>
                        </li>
                        {/* {userpermissions['permission'] && <li><Link to={'/permission'} className="rounded">permission</Link></li>}  */}
                      </ul>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </PerfectScrollbar>
        )}
        <div className="dropdown fixed-bottom-dropdown">
          <a
            href="/"
            className="d-flex align-items-center text-decoration-none dropdown-toggle"
            id="dropdownUser2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 5,
                color: "#fff",
                cursor: "pointer",
              }}
            >
              {collapsed ? (
                (loader=="yes"?(<Skeletonloader variant="circular" height={20} width="20px" borderRadius={20} />):(<FaHotel style={{ fontSize: "25px" }} />))
              //  <FaHotel style={{ fontSize: "25px" }} />
              ) : (
               (loader==="no"?
                (<span
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "bold",
                    maxWidth: "200px",
                    overflowX: "auto"
                  }}
                >
                  {localStorage.getItem("roleName")?.length > 0
                    ? localStorage.getItem("roleName")
                    : "Switch property"}
                </span>):( <Skeletonloader variant="rectangular" height={20} width="170px" borderRadius={5} />))
              )}
            </div>

            {/* <span>{sessionStorage.getItem('role') || 'User'}</span> */}
          </a>
          {/* <div style={{ border: "2px solid green" }}> */}
          <ul
            className="dropdown-menu text-small shadow"
            aria-labelledby="dropdownUser2"
            style={{
              width: "90%",
              margin: "auto",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
            }}
          >
            {propertyData?.map((item, index) => {
              return (
                <li
                  onClick={() => Confirm(item?.redirect, item?.code, item)}
                  style={{
                    paddingBlock: "10px",
                    paddingInline: "10px",
                    cursor: "pointer",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "gray",
                    fontWeight: "bold",
                  }}
                  className="li-pro"
                  key={index}
                >
                  <span> {item?.name}</span>
                </li>
              );
            })}

            <div
              style={{
                position: "absolute",
                bottom: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: "10px solid white", // Adjust color and size as needed
              }}
            />
          </ul>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
