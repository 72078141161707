import React from "react";
import "../../assets/css/coupons.css";
import couponve from "../../assets/images/Vectorcoupon.png";
import copimage from "../../assets/images/rb_3649.png";
import Skeletonloader from "../../components/skeletonloader/Skeletonloader";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa6";
const CouponGrid = (props) => {
  const formatDateRange = (from, to) => {
    const fromDate = moment(from).format("D");
    const toDate = moment(to).format("D MMM, YYYY");
    const sameMonth = moment(from).isSame(to, "month");

    return sameMonth
      ? `${fromDate} to ${toDate}` // If in the same month
      : `${fromDate} ${moment(from).format("MMM")} to ${toDate}`; // If in different months
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code); 
  };

  const handleSetActiveTab = (tab) => {
    props.setActiveTab(tab);
    if (tab === "create new") {
      props.setEditC(false); // Reset edit state when navigating to "create new"
    }
  };


  const filteredCoupons = props.coupon.filter((coupon) => {
    const titleMatch = props.filters.title
      ? coupon.title.toLowerCase().includes(props.filters.title.toLowerCase())
      : true;
    const categoryMatch = props.filters.category
      ? coupon.category === props.filters.category
      : true;

    const startDateMatch = props.filters.startDate
      ? new Date(coupon.valid_from) >= new Date(props.filters.startDate)
      : true;

    const endDateMatch = props.filters.endDate
      ? new Date(coupon.valid_to) <= new Date(props.filters.endDate)
      : true;
    // const dateMatch = props.filters.date
    //   ? new Date(coupon.valid_to) >= new Date(props.filters.date)
    //   : true;
    return titleMatch && categoryMatch && startDateMatch && endDateMatch;
  });
  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: 25 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            backgroundColor: "#f8f8f8", // Optional container background
          }}
        >
          {/* Left side: History Button */}
          <button
            onClick={() => props.setActiveTab("history")}
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #ccc",
              padding: "8px 16px",
              cursor: "pointer",
              borderRadius: 8,
            }}
          >
            History
          </button>

          {/* Right side: Sort By select and Create New button */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* Sort By Select */}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Sort By:
              <select
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #ccc",
                  padding: "8px",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
              >
                <option value="date">Date</option>
                <option value="name">Name</option>
                <option value="priority">Priority</option>
              </select>
            </label>

            {/* Create New Button */}
            {props?.couponpermission?.Coupon?.includes('store') &&    <button
              onClick={()=>handleSetActiveTab('create new')}
              style={{
                backgroundColor: "#47ADFF",
                color: "white",
                border: "none",
                padding: "8px 16px",
                cursor: "pointer",
                borderRadius: "8px", 
              }}
            >
              Create New
            </button>}
         
          </div>
        </div>
      </div>
      <div className="coupon-grid">
        {props.loading ? (
         <div style={{display:"flex",width:"100%", gap:25}}> 
          { Array.from({ length: 4 }).map((_, index) => (
            <Skeletonloader  key={index} variant={"rectangular"} width={200} height={200} />
          ))}
         </div>
        ) : (
          <>
            {props.coupon?.length > 0 &&
                filteredCoupons.map((coupon, index) => (
                <div key={index} className="coupon-card">
                  <img
                    style={{}}
                    src={coupon.img || copimage}
                    onError={(e) => {
                      e.target.src = copimage; // Fallback image URL
                      
                    }}
                  />
                  <div className="coupontime">
                    <div className="coupontimes">
                      <img onError={copimage} src={couponve} />
                      <p>
                        {formatDateRange(coupon.valid_from, coupon.valid_to)}
                      </p>
                    </div>
                  </div>
                  <h6>{coupon?.title}</h6>
                  <span style={{color:'grey', fontSize:'12px'}}><b>Category :</b> {coupon?.category}</span>


                  <div className="copy-coupon-container">
                    <span className="coupon-code">
                    <span>
                      <FaRegCopy className="copy-icon"onClick={() => handleCopy(coupon.code)} />{" "}
                    </span>
                      <span className="">{coupon.code}</span>
                    </span>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CouponGrid;
